<template>
  <div class="contents" id="contents">
    <div class="inner-sm">
      <!-- 개인정보 처리방침 -->
      <div class="terms-title">
        <h3>{{ $t('content.signup.popup.PersonalInfo.txt01') }}</h3>
      </div>

      <div class="view-box">
        <PrivacyText />
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import PrivacyText from './component/PrivacyText.vue';

/**
 * 개인정보 처리방침
 */
export default {
  name: 'Privacy',
  head: {
    meta: [
      { name: 'viewport', content: 'width=1920' },
    ],
  },
  metaInfo() {
    return {
      meta: this.$route.meta.metaInfo
    };
  },
  mixins: [
    mixinHelperUtils,
  ],

  components: {
    PrivacyText,
  },
};
</script>
